<template>
  <v-app>
    <slot></slot>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      rolesName: "ROLE/rolesName"
    })
  },
  created() {
    if (this.rolesName && this.rolesName.length === 0) {
      this.$store.dispatch("ROLE/getRolesInvoices");
    }
  }
};
</script>
